<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Thermodynamics of Salts Experiment: Results</h2>

      <p class="mb-5">
        Enter all of the data collected during the simulation in the table below. Note that the heat
        capacity of both the solution inside the can and that in the cooling jacket can be assumed
        to be the same as water, i.e.
        <stemble-latex content="$\text{4.184 J}\,\text{g}^{-1 \, \circ}\text{C}^{-1}.$" />
        The heat capacity of the aluminum can can be assumed to be
        <stemble-latex content="$\text{0.900 J}\,\text{g}^{-1 \, \circ}\text{C}^{-1}.$" />
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <td style="width: 16%">
              <stemble-latex content="$\text{Variable}$" />
            </td>
            <td style="text-align: center; width: 21%">
              <stemble-latex content="$\ce{KBr}$" />
            </td>
            <td style="text-align: center; width: 21%">
              <stemble-latex content="$\ce{LiCl}$" />
            </td>
            <td style="text-align: center; width: 21%">
              <stemble-latex content="$\ce{NH4Br}$" />
            </td>
            <td style="text-align: center; width: 21%">
              <stemble-latex content="$\ce{KNO3}$" />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\text{Mass of}$" />
              <stemble-latex content="$\text{ can}$" />
              <stemble-latex content="$\text{ (g)}$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massCan1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massCan2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massCan3"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massCan4"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Mass of water}$" />
              <stemble-latex content="$\text{ inside}$" />
              <stemble-latex content="$\text{ can (g)}$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massCanWater1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massCanWater2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massCanWater3"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massCanWater4"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Mass of }$" />
              <stemble-latex content="$\text{ salt}$" />
              <stemble-latex content="$\text{ (g)}$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massSalt1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massSalt2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massSalt3"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massSalt4"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Mass of water}$" />
              <stemble-latex content="$\text{ added to}$" />
              <stemble-latex content="$\text{ jacket (g)}$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massJacketWater1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massJacketWater2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massJacketWater3"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.massJacketWater4"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{T}_\text{i}$" />
              <stemble-latex content="$\,(^\circ\text{C})$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ti1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ti2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ti3"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ti4"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{T}_\text{f}$" />
              <stemble-latex content="$\,(^\circ\text{C})$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Tf1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Tf2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Tf3"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Tf4"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\Delta\text{T}$" />
              <stemble-latex content="$\,(^\circ\text{C})$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.deltaT1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.deltaT2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.deltaT3"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.deltaT4"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\Delta\text{H}_\text{rxn,exp}$" />
              <stemble-latex content="$\,(\text{J})$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.deltaHrxnJ1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.deltaHrxnJ2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.deltaHrxnJ3"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.deltaHrxnJ4"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\Delta\text{H}_\text{rxn,exp}$" />
              <stemble-latex content="$\,(\text{J/mol})$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.deltaHrxn1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.deltaHrxn2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.deltaHrxn3"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.deltaHrxn4"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="width: 16%" class="py-2 my-2">
              <stemble-latex content="$\Delta\text{H}_\text{rxn,lit}$" />
              <stemble-latex content="$\,(\text{J/mol})$" />
            </td>
            <td style="text-align: center; width: 21%" class="py-2 my-2">
              <stemble-latex content="$19900$" />
            </td>
            <td style="text-align: center; width: 21%" class="py-2 my-2">
              <stemble-latex content="$-3.70\times 10^{4}$" />
            </td>
            <td style="text-align: center; width: 21%" class="py-2 my-2">
              <stemble-latex content="$16800$" />
            </td>
            <td style="text-align: center; width: 21%" class="py-2 my-2">
              <stemble-latex content="$34900$" />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\%\,\text{Error}$" />
              <stemble-latex content="$\,(\%)$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError3"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError4"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import ChemicalSymbolInput from '@/tasks/components/inputs/ChemicalSymbolInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';
import STextarea from '@/common/components/STextareaShort.vue';

export default {
  name: 'UBC_Chem154_CalorimetryLab_Results',
  components: {
    STextarea,
    LatexNumber,
    NumberValue,
    ChemicalSymbolInput,
    ChemicalNotationInput,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        massCan1: null,
        massCan2: null,
        massCan3: null,
        massCan4: null,
        massCanWater1: null,
        massCanWater2: null,
        massCanWater3: null,
        massCanWater4: null,
        massSalt1: null,
        massSalt2: null,
        massSalt3: null,
        massSalt4: null,
        massJacketWater1: null,
        massJacketWater2: null,
        massJacketWater3: null,
        massJacketWater4: null,
        Ti1: null,
        Ti2: null,
        Ti3: null,
        Ti4: null,
        Tf1: null,
        Tf2: null,
        Tf3: null,
        Tf4: null,
        deltaT1: null,
        deltaT2: null,
        deltaT3: null,
        deltaT4: null,
        deltaHrxnJ1: null,
        deltaHrxnJ2: null,
        deltaHrxnJ3: null,
        deltaHrxnJ4: null,
        deltaHrxn1: null,
        deltaHrxn2: null,
        deltaHrxn3: null,
        deltaHrxn4: null,
        pctError1: null,
        pctError2: null,
        pctError3: null,
        pctError4: null,
      },
    };
  },
};
</script>
<style scoped></style>
